// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
import { firebase } from '../firebaseConfig'
const db = firebase.firestore();

class SportDataService {
  getAll() {
    return db.collection("sports");
  }

  // create(tutorial) {
  //   return db.push(tutorial);
  // }

  // update(key, value) {
  //   return db.child(key).update(value);
  // }

  // delete(key) {
  //   return db.child(key).remove();
  // }

  // deleteAll() {
  //   return db.remove();
  // }
}

export default new SportDataService();
